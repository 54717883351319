import styles from "./SimpleReportPdf.module.css";
import { useParams } from "react-router-dom";
import React from "react";
import { cx } from "utilities/cx";
import { useRef } from "react";
import { Report, ParagraphSection } from "api/reports/models";
import dotImg from "../../assets/images/dot.svg";
import { PDFExport } from "@progress/kendo-react-pdf";
import { dateFns } from "utilities/dateFns";
import {
  LineIconDict,
  printNumber, printNumberDiag
} from "./constants";

import { fileDownloader } from "fileDownloader";
import { fileFactory, getMinMax } from "api/reports/calls";
// import { stableValueHash } from "react-query/types/core/utils";
import { htmlRender } from "utilities/htmlRender";
import "chartjs-plugin-datalabels";


import Barcode from 'react-barcode';

import {
  Chart,
  ChartLegend,
  ChartSeries,
  ChartSeriesItem,
  ChartSeriesLabels,
  ChartTooltip,
} from "@progress/kendo-react-charts";


export const PrintSimplePdfButton = ({ report }: { report: Report }) => {
  const pdfExportComponent = useRef(null);
  const { code } = useParams<{ code: string }>();
  const lang = report.language;
  // const handleExportWithComponent = () => {
  //   //@ts-ignore
  //   pdfExportComponent.current.save();
  // };

  async function handleExportWithComponent() {
     const { url, name } = fileFactory.simpleReportPdf(code);
     const response = await fileDownloader({
       url,
       name: `raport-${code}`,
       type: "pdf",
     });
     if (response.status === "success") {
     } else {
      //@ts-ignore
       pdfExportComponent.current.save();
     }
  };

  const sortedReports = (() => {
    function compare(
      a: Report["data"][number]["sections"][number],
      b: Report["data"][number]["sections"][number],
    ) {
      if (a.order < b.order) {
        return -1;
      }
      if (a.order > b.order) {
        return 1;
      }
      return 0;
    }

    const sortedPages = report.data.reduce(
      (acc: Report["data"], el: Report["data"][number], index: number) => {
        if (el.title === "III POTENCJAŁ FUNKCJONALNY BAKTERII" || el.title === "IV ADDITIONAL INFORMATION" || 
        el.title === "III FUNCTIONAL POTENTIAL OF BACTERIA" || el.title === "IV ADDITIONAL INFORMATION" 
        ) {
          var section1, section2, section3;
          if (el.title === "III POTENCJAŁ FUNKCJONALNY BAKTERII" || el.title === "III FUNCTIONAL POTENTIAL OF BACTERIA") {
            section1 = el.sections.slice(0, 6);
            section2 = el.sections.slice(6, 12);
            section3 = el.sections.slice(12, 19);
          } else {
            section1 = el.sections.slice(0, 8);
            section2 = el.sections.slice(8, 16);
            section3 = el.sections.slice(16, 23);  
          }
          acc.push({
            ...el,
            //@ts-ignore
            sections: [...section1.sort(compare)],
          });
          acc.push({
            ...el,
            //@ts-ignore
            sections: [...section2.sort(compare)],
            title: ""
          });
          acc.push({
            ...el,
            //@ts-ignore
            sections: [...section3.sort(compare)],
            title: ""
          });
        } else if (el.title === "I TEST RESULTS") {
            var sections1 = el.sections.sort(compare).slice(0, 1)
            var copiedsec = {  description: [], plotdescription: "",
              diagram: {"type": "table", "title": "", "values": [] },
              order: 0,
              title: "",
              type: "paragraph",
              value: ""
            } as ParagraphSection 
            var sections2 = el.sections.sort(compare)
            if (sections1[0].diagram && sections1[0].diagram.type === "table"
              && copiedsec.diagram && copiedsec.diagram.type === "table"
            ) {
              copiedsec.diagram.values = sections1[0].diagram.values.slice(14)
              sections1[0].diagram.values = sections1[0].diagram.values.slice(0, 14)
              sections2[0] = copiedsec
            }
            acc.push({
              ...el,
              sections: [...sections1],
            });

            acc.push({
              ...el,
              //@ts-ignore
              sections: [...sections2],
              title: ""
            });
          } else {
          acc.push({
            ...el,
            sections: el.sections.sort(compare),
          });
        }

        return acc;
      },
      [],
    );

    return sortedPages;
  })();



  return (
    <div>
      <button className="btnPrimary" onClick={() => handleExportWithComponent()}>
        Pobierz prosty raport
      </button>
      <div
        style={{
          position: "absolute",
          left: "-800000px",
          top: "-800000px",
        }}
      >
        <PDFExport
          fileName={`raport-${code}.pdf`}
          ref={pdfExportComponent}
          paperSize="A4"
          margin="0.5cm"
          scale={1 / 1.2}
          creator="GenXone SA"
          // imageResolution={80000}
        >
          <React.Fragment>
            lang = sortedReports
            {sortedReports.map((el, index) => (
               <div className={el.page === 5 ? cx() : cx(styles.singlePage, "page-break")} key={el.page}>
                <div>
                  {el.page === 5 ? <></> : 
                  <div className={styles.pageHeader}>
                    <div className="d-flex flex-column justify-content-end">
                      {/* <div className={styles.title}>{code}</div>  */}
                    </div>
                    <div className={cx(styles.barcode)}>
                      <Barcode value={code} height={25} format="CODE128"  fontSize={11} />
                    </div>
                  </div> }

                  {index===0 ? <><div className={cx(
                                    styles.titleCenter,
                                    styles.mb3,
                                    styles.mt2,
                                  )}>
                  {lang==="en" ? htmlRender("NanoDiagBiom - extended report") : htmlRender("NanoDiagBiom - raport rozszerzony")}
                    <br/>
                  {lang==="en" ? htmlRender("Assessment of the intestinal microbiota composition") : htmlRender("Ocena kompozycji mikrobioty jelitowej")}
                  </div></>
                   : <></>}
                  <div className={cx( 
                                    styles.boldTitle,
                                    styles.underlinedTitle,
                                    styles.mb3,
                                    styles.mx3, 
                                  )}>{el.title}
                  </div>
                  
                  <div className={(styles.content, "row")}>
                    {el.sections.map((section) => {
                      if (section.type === "info") {
                        return ( <div>
                          <div className="row">
                              <div className={cx(styles.mt1, styles.mx3, styles.boldText)}>
                                  {section.title}
                              </div>              
                              <div className={ "col-12"} >
                                {section.description.map((el) => (
                                    <div
                                      className={cx(styles.description, styles.mb1, styles.mx3)}
                                    >{htmlRender(el)}
                                    </div>
                                ))}
                              </div>
                            </div>
                          </div>
                        );
                      }
                      // report.language
                      if (section.type === "paragraph") {
                        return (<div>
                          <div className="row">
                            {section.title &&  (
                                (section.title === "V INFORMACJA O GRUPIE REFERENCYJNEJ I KRYTERIA ANALIZY DANYCH" || section.title === "V INFORMATION ABOUT THE REFERENCE GROUP AND DATA ANALYSIS") ? 
                                <div className={cx( 
                                  styles.boldTitle,
                                  styles.underlinedTitle,
                                  styles.mb3,
                                  styles.mt4,
                                  styles.mx3, 
                                )}>{section.title}
                                </div> : <div className={cx(styles.mb1, styles.mt2, styles.mx3, styles.smallTitle)}>
                                    {section.title}
                                </div>
                            )}                              
                              <div className={ "col-12"} >
                                {section.description.map((el) => (
                                    <div
                                      className={cx(styles.description, styles.mt1, styles.mb3, styles.mx3)}
                                    >{htmlRender(el)}
                                    </div>
                                ))}
                              </div>
                            </div>
                          {section.diagram && section.diagram.type === "table" && (
                          <div className={cx(styles.me0, styles.my2, styles.pl4)} >
                            <div className="row">
                              <div className={cx(styles.tableheader, styles.tableheaderfirst)} style={{ width: `40%` }}>
                                {lang==="en" ? "BACTERIA" : "BAKTERIE" }
                              </div>
                              <div className={cx(styles.tableheader)} style={{ width: `15%` }}>
                              {lang==="en" ? "YOUR RESULT" : "UZYSKANY WYNIK" }
                              </div>
                              <div className={cx(styles.tableheader)} style={{ width: `10%` }}>
                              {lang==="en" ? "BELOW" : "PONIŻEJ" }
                              </div>
                              <div className={cx(styles.tableheader)} style={{ width: `20%` }}>
                              {lang==="en" ? "REFERENCE RANGE" : "GRUPA REFERENCYJNA" }
                              </div>
                              <div className={cx(styles.tableheader)} style={{ width: `10%` }}>
                              {lang==="en" ? "ABOVE" : "POWYŻEJ" }
                              </div>
                            </div>
                            {section.diagram.values.map((rowval) => {
                              return(<div className="row"  >
                                <div className={cx(styles.tablerow, styles.tablerowfirst, styles.tabletext, styles.italicText)} style={{ width: `40%` }}>
                                    {rowval.name}
                                </div>
                                <div className={cx(styles.tablerow, styles.tableresult)} style={{ width: `15%` }}>
                                    {printNumberDiag(rowval.value, true)}%
                                </div>
                                <div className={cx(styles.tablerow, styles.tableLabel)} style={{ width: `10%`, textAlign: "right" }}>
                                    {printNumber(rowval.refMin, true)}%
                                </div>
                                <div className={cx(styles.tablerow)} style={{ width: `20%` }}>
                                  <div>
                                    {(rowval.refMax !== rowval.refMin) ? 
                                    <div className={styles.tableBarChartBar} style={{ width: `${
                                            (rowval.plotMax - rowval.plotMin)/(rowval.refMax - rowval.refMin)*100 + 2
                                          }%`,
                                          marginLeft: `${(rowval.plotMin - rowval.refMin)/(rowval.refMax - rowval.refMin)*100 - 1
                                        }%`, }}>
                                      <img className={styles.tableWave} src={LineIconDict[rowval.name]} alt="" />
                                      <img className={styles.barChartMarkerSmall}
                                              style={{ left: `${
                                              getMinMax(
                                                Number((rowval.value - rowval.plotMin)/(rowval.plotMax - rowval.plotMin)*100), 
                                                1, 99 )   }%`, }}
                                          src={dotImg} alt=""
                                      />
                                      {/* <span className={styles.divider} style={{ left: `${
                                            (rowval.refMin - rowval.plotMin)/(rowval.plotMax - rowval.plotMin)*100
                                          }%`,
                                      }}></span>
                                      <span className={styles.divider} style={{ left: `${
                                            (rowval.refMax - rowval.plotMin)/(rowval.plotMax - rowval.plotMin)*100
                                          }%`,
                                      }}></span> */}
                                      </div> : <div className={styles.tableBarChartBar} style={{ width: "32%",
                                          marginLeft: "99%"}}>
                                      <img className={styles.tableWave} src={LineIconDict[rowval.name]} alt="" />
                                      <img className={styles.barChartMarkerSmall}
                                              style={{ left: `${
                                              getMinMax(
                                                Number((rowval.value - rowval.plotMin)/(rowval.plotMax - rowval.plotMin)*100), 
                                                5, 95 )   }%`, }}
                                          src={dotImg} alt=""
                                      /></div>
                                    }

                                  </div>
                                </div>
                                <div className={cx(styles.tablerow, styles.tableLabel)} style={{ width: `10%`, textAlign: "left" }}>
                                  {printNumber(rowval.refMax, true)}%
                                </div>
                              </div>)})}
                            </div> )}
                          </div>
                        );
                      }

                      if (section.type === "box") {
                        return (
                        <div className={styles.box}>
                          <div className="row">
                          <div className={section.diagram && section.diagram.type === "circle" ? "col-4": "col-6"} >

                              {section.title && (
                              <div className={cx(styles.mb1)}>
                                <div className={cx(styles.boxTitle)} >
                                  {section.title}
                                </div>
                                <div
                                  // dangerouslySetInnerHTML={{ __html: section.value }}
                                  className={cx(styles.me3, styles.my0, styles.subTitle)}
                                />{htmlRender(section.value)} 
                              </div>
                              )}

                                {section.description.map((el) => (
                                  <div className={cx(styles.smallDescription, styles.my1, styles.me0)}>
                                    {htmlRender(el)} 
                                  </div>
                                ))}
                            </div>
                            {section.diagram && section.diagram.type === "circle" && (
                              <div className="col-8">
                                <div className={styles.pieChartContainer}>
                                <Chart>
                                  <ChartTooltip />
                                  <ChartSeries>
                                    <ChartSeriesItem
                                      type="donut"
                                      data={ section.diagram.values.map(({ value, name }, index) => 
                                        {return({value: value, name: name,
                                          // color: ["#11A5D6","#FEEDB8","b28ce3", "#3D3C66", "#FFF7DF", "#9392AA", "#D2E8EE", "#AFE9DD","#aea37d", "#7030A0","#9291A9","#58E5F7", "#1e1c4d", "#323e4f"][index % 14] }
                                          color: ["#11A5D6","#FEEDB8", "#b28ce3", "#AFE9DD", "#3D3C66", "#FFF7DF",
                                                  "#9291A9",  "#ffe39b", "#1e1c4d", "#D2E8EE","#58E5F7", "#543a98"][index % 12] }
                                          // color:  ["#70ad47", "#5b9bd5", "#ffc000", "#43682b", "#255e91",  "#997300"][index % 6] }
                                        )})}
                                      categoryField="name"
                                      field="value"
                                      colorField="color"
                                      border={{color: "#323e4f", width: 0.5}}
                                      padding={0}
                                      autoFit={true}
                                    >
                                      <ChartSeriesLabels
                                        color="#323e4f" background="none" position="outsideEnd"
                                        content={labelContent}
                                        distance={25}
                                        font="14px Lato,Open-Sans,sans-serif" margin={0} padding={0}
                                      />
                                    </ChartSeriesItem>
                                  </ChartSeries>
                                  <ChartLegend visible={false} />
                                </Chart>
                                </div>
                              </div>
                            )}
                            {(section.diagram?.type === "line" || section.diagram?.type === "lineperc") && (
                              <div className="col-6">
                                <div className={styles.resultLabel}>{lang==="en" ? "YOUR RESULT" : "UZYSKANY WYNIK:"}</div>
                                  {section.diagram?.type === "line" && <div className={styles.result}> <strong>{printNumber(section.diagram.result, false)}</strong> </div>}
                                  {section.diagram?.type === "lineperc" && <div className={cx(styles.result, styles.ml2)}> <strong>{printNumber(section.diagram.result, true)}</strong> %</div>}
                                      
                                  <div className={styles.lineBox }>
                                    <div className={styles.barChartBar}>
                                        <img
                                          className={styles.wave}
                                          src={LineIconDict[section.diagram.img]}
                                          alt=""
                                        />
                                        <img
                                          className={styles.barChartMarker}
                                          style={{ 
                                            left: `${
                                              getMinMax(
                                                Number((section.diagram.result - section.diagram.plotMin)/(section.diagram.plotMax - section.diagram.plotMin)*100), 
                                                5, 95 )
                                            }%`,
                                          }}
                                          src={dotImg}
                                          alt=""
                                        />
                                        <span className={styles.divider}
                                        style={{ 
                                          left: `${
                                            (section.diagram.referenceMin - section.diagram.plotMin)/(section.diagram.plotMax - section.diagram.plotMin)*100
                                          }%`,
                                        }}></span>

                                        <span className={styles.divider}
                                        style={{ 
                                          left: `${
                                            (section.diagram.referenceMax - section.diagram.plotMin)/(section.diagram.plotMax - section.diagram.plotMin)*100
                                          }%`,
                                        }}></span>
                                    </div>
                                    <div className={styles.barChartBox}>
                                      
                                      <div
                                        className={cx(
                                          styles.barChartLabelsBox,
                                          "d-flex",
                                        )}
                                      >
                                      <div
                                          className={cx(
                                            styles.barChartLabel,
                                            styles.barChartLabelLeft,
                                          )}
                                          style={{ 
                                            left: `${
                                              (section.diagram.referenceMin - section.diagram.plotMin)/(section.diagram.plotMax - section.diagram.plotMin)*100
                                            }%`,
                                          }}
                                        >
                                          {section.diagram?.type === "line" && <div className={styles.barChartLabelDiv} >{printNumber(section.diagram.referenceMin, false)}</div>}
                                          {section.diagram?.type === "lineperc" && <div className={styles.barChartLabelDiv}>{printNumber(section.diagram.referenceMin, true)}%</div>}
                                          
                                      </div>
                                      <div
                                          className={cx(
                                            styles.barChartLabel,
                                            styles.barChartLabelRight,
                                          )}
                                          style={{ 
                                            left: `${
                                              (section.diagram.referenceMax - section.diagram.plotMin)/(section.diagram.plotMax - section.diagram.plotMin)*100
                                            }%`,
                                          }}
                                        >
                                          {section.diagram?.type === "line" && <div className={styles.barChartLabelDiv}>{printNumber(section.diagram.referenceMax, false)}</div>}
                                          {section.diagram?.type === "lineperc" && <div className={styles.barChartLabelDiv}>{printNumber(section.diagram.referenceMax, true)}%</div>}
                                      </div>
                                    </div>
                                    <div className={cx(styles.resultLabel, styles.downLabel)}>{lang==="en" ? "REFERENCE RANGE:" : "GRUPA REFERENCYJNA:"}</div>
                                  </div>
                                </div>


                              </div>
                            )} 
                            <div className={styles.outlier}>
                                {(section.diagram?.type === "line" || section.diagram?.type === "lineperc") && 
                                (section.title === "12. BAKTERIE BEZWZGLĘDNIE PATOGENNE" || section.title === "12. PATHOGENIC BACTERIA") && ((section.diagram.result < section.diagram.referenceMin) || (section.diagram.result > section.diagram.referenceMax)) &&
                                  <div>{lang==="en" ? "Result is outside of the range observed in reference population. We advise to reach out to a specialist." : "*Wynik wykracza poza zakres wartości dla populacji referencyjnej. Zalecana konsultacja ze specjalistą."}</div> 
                                }
                            </div>
                        </div>
                      </div>
                      );}
                      
                    return <div></div>;
                    })}
                  </div>
                </div> 
                {/* <div className={styles.footer}>
                  <div
                    className={cx(
                      styles.footerText,
                      "d-flex align-items-center justify-content-between",
                    )}
                  >
                    <div>
                      Numer badania: {code}. Data pobrania próbki:{" "}
                      {dateFns.format(new Date(report.sampleDate), "yyyy.MM.dd")}{" "}
                    </div>
                    <div>strona {index + 1}</div>
                  </div>
                  <div className={styles.stripe}></div>
                </div> */}
              </div>
            ))}
          </React.Fragment>
        </PDFExport>
      </div>
    </div>
  );
};


const labelContent = (e: any) => `${e.category}: ${printNumber(e.value, true)}%`;
